export const validate_date = (month, day) => {
  //month must resolve to an integer between 1 and 12 inclusive, day must resolve to an integer between 1 and 31 inclusive
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  if (isNaN(parsedMonth) || isNaN(parsedDay)) {
    return false;
  }

  if (
    parsedMonth >= 1 &&
    parsedMonth <= 12 &&
    parsedDay >= 1 &&
    parsedDay <= 31
  ) {
    // Valid date
    return true;
  } else {
    // Invalid date
    return false;
  }
};
