import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Unsub from "./pages/Unsub";
import UnsubFromOrdStat from "./pages/UnsubFromOrdStat";
// import SelfOnboard from "./pages/SelfOnboard";
// import Session from "./pages/Session";
import NotifLanding from "./pages/NotifLanding";
import SignIn from "./pages/SignIn";

function RedirectToHome() {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/home");
  }, [navigate]);

  return null;
}

function App() {
  return (
    <Router>
      <div className='relative min-h-screen p-4'>
        <div className='flex items-center justify-center h-full'>
          <img
            src='/logo.svg'
            alt='Logo'
            className='sticky top-8 left-0 m-4 mb-2'
          />
        </div>
        <div className='flex items-center justify-center h-full'>
          <Routes>
            <Route path='/' element={<RedirectToHome />} />
            <Route path='/home' element={<Home />} />
            <Route path='/u/:mode/:unsub_code' element={<Unsub />} />

            <Route path='/t/:url_hash' element={<NotifLanding />} />
            <Route path='/sim/t/:url_hash' element={<NotifLanding />} />
            <Route
              path='/sim/unsub/:unsub_code'
              element={<UnsubFromOrdStat />}
            />

            {/* <Route path="/session" element={<Session />} />
            <Route path="/session/:page/*" element={<Session />} /> */}

            {/* <!-- session not established yet --> */}
            <Route path='/signin/:mode/:referral_code' element={<SignIn />} />
            {/* <Route path="/onboard" element={<SelfOnboard />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
